<template>
        <div class="loginPanel">
                <div class="lpTitle">Изменение пароля</div>
                <inputPassword placeholder="Новый пароль" ref="ps" class="mt50"
                               @updateps="updateps"></inputPassword>
                <inputPassword placeholder="Повторите пароль" ref="ps1" class="mt10"
                               @updateps="updateps1"></inputPassword>
                <!--                <input type="password" placeholder="Введите новый пароль" class="ps mt50" v-model="ps"  :class="{error : errorText}"/>
                                <input type="password" placeholder="Подтвердите пароль" class="ps mt10" v-model="ps1" :class="{error : errorText}"/>-->
                <div class="dvError mt20" :class="{viewError : errorText}">{{ errorText }}</div>
                <buttonRed class="binput mt30" text='Сохранить' @clickButton="postPass" :disable="ps == '' || ps1==''"></buttonRed>
        </div>
</template>

<script>

import g from "../../globals";
import ButtonRed from "../../common/ButtonRed";
import InputPassword from "../../common/InputPassword";

export default {
        props: ["forgot"],
        components: {ButtonRed, InputPassword},
        data: function () {
                return {
                        ps: "",
                        ps1: "",
                        errorText: null
                }
        },

        methods: {
                updateps() {
                        this.ps = this.$refs.ps.getPS()
                },
                updateps1() {
                        this.ps1 = this.$refs.ps1.getPS()
                },
                async postPass() {
                        try {
                                this.$refs.ps.clearError();
                                this.$refs.ps1.clearError();
                                if (this.$refs.ps.getPS().length < 8 || this.$refs.ps1.getPS() != this.$refs.ps.getPS()) {
                                        if (this.$refs.ps.getPS().length < 8) this.errorText = "Пароль содержит менее 8 символов";
                                        else this.errorText = "Пароли не совпадают";
                                        this.$refs.ps.setError();
                                        this.$refs.ps1.setError();
                                        return
                                }

                                let res = await g.postUrl("auth/updatePassword", {
                                        forgot: this.forgot,
                                        ps: this.$refs.ps.getPS()
                                });
                                this.$emit("updatePasswordOk");
                        } catch (e) {
                                this.$emit("error", e);
                        }
                }
        }
}
</script>
