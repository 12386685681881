<template>
	<div class="loginPanel">
                <div class="messageTitle messageRight">Успешно</div>
                <div class="messageText mt50">Пароль был успешно изменен.</div>
                <buttonRed class="binput mt50" text='Продолжить' @clickButton="gotoLogin"></buttonRed>
        </div>
</template>

<script>

	import ButtonRed from "../../common/ButtonRed";

        export default {
	        props : ["email"],
                components : {ButtonRed},
		data : function() {
			return {
			}
		},

                methods : {
                        gotoLogin() {
                                location.href = "/";
                        }
                }
	}
</script>
