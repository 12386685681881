<template>
        <div class="loginPanel">
                <div class="bigText" v-html="errorMessage"></div>
                <buttonNone class="bclose mt30" v-if="wait" text='Отправить повторное письмо'
                            @clickButton="sendPost"></buttonNone>
                <buttonNone class="bclose mt30" text='Продолжить' @clickButton="gotoLogin"></buttonNone>
        </div>
</template>

<script>

import ButtonNone from "../../common/ButtonNone";
import g from "../../globals";

export default {
        props: ["email", "error", "back"],
        components: {ButtonNone},
        data: function () {
                return {
                        errorMessage: "",
                        wait: false
                }
        },

        mounted() {
                if (typeof (this.error) == "object") {
                        this.errorMessage = this.error.message;
                        this.wait = (this.error.property == "wait");
                } else {
                        this.errorMessage = this.error;
                }
        },

        methods: {
                gotoLogin() {
                        if (!this.back) location.href = "/";
                        else this.$emit("gotoBack");
                },

                async sendPost() {
                        g.block(true);
                        await g.postUrl("auth/postConfirm", {email: this.email});
                        g.block(false);
                        location.href = "/";
                }
        }
}
</script>
