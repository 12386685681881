<template>
        <div class="inputPassword" :class="{focus : focus, error : error}">
                <input :type="tp" :placeholder="placeholder" class="ps" v-model="ps"
                       @focusin="focusFunc" @focusout="() => {focus = false}"
                       @keyup="onkd"/>
                <div class="psButton" v-show="ps != '' " @click="show">{{ btn }}</div>
        </div>
</template>

<script>
export default {
        props: ["placeholder"],
        data: function () {
                return {
                        ps: "",
                        focus: false,
                        tp: "password",
                        btn: "Показать",
                        error: false
                }
        },

        methods: {
                show() {
                        if (this.tp == "password") {
                                this.tp = "text";
                                this.btn = "Скрыть";
                        } else {
                                this.tp = "password";
                                this.btn = "Показать";
                        }
                },

                getPS() {
                        return this.ps
                },
                clear() {
                        this.ps = "";
                },
                setError() {
                        this.error = true
                },
                clearError() {
                        this.error = false
                },
                onkd() {
                        this.$emit('updateps')
                },
                focusFunc() {
                        this.focus = true
                        this.$emit('focus')
                }
        }
}
</script>
